// these are properties not on the vendor being fetched from the V1 API endpoint utilized by Union's current Typeahead component, stubbed to avoid errors

export const defaultInfo = {
	categories: [{ code: {} }],
	categoryInfo: { plural: { slug: {}, term: {} } },
	photoSummary: '',
	breadcrumbInfo: { cityUrl: '' },
	mediaSummary: { total: {} },
	settings: {},
};

// includes Canadian provinces and US territories
export const stateAbbreviationNameSlugMap = {
	AB: { stateName: 'Alberta', stateSlug: 'alberta' },
	AK: { stateName: 'Alaska', stateSlug: 'alaska' },
	AL: { stateName: 'Alabama', stateSlug: 'alabama' },
	AR: { stateName: 'Arkansas', stateSlug: 'arkansas' },
	AS: { stateName: 'American Samoa', stateSlug: 'american-samoa' },
	AZ: { stateName: 'Arizona', stateSlug: 'arizona' },
	BC: { stateName: 'British Columbia', stateSlug: 'british-columbia' },
	CA: { stateName: 'California', stateSlug: 'california' },
	CO: { stateName: 'Colorado', stateSlug: 'colorado' },
	CT: { stateName: 'Connecticut', stateSlug: 'connecticut' },
	DC: { stateName: 'District Of Columbia', stateSlug: 'district-of-columbia' },
	DE: { stateName: 'Delaware', stateSlug: 'delaware' },
	FL: { stateName: 'Florida', stateSlug: 'florida' },
	GA: { stateName: 'Georgia', stateSlug: 'georgia' },
	GU: { stateName: 'Guam', stateSlug: 'guam' },
	HI: { stateName: 'Hawaii', stateSlug: 'hawaii' },
	IA: { stateName: 'Iowa', stateSlug: 'iowa' },
	ID: { stateName: 'Idaho', stateSlug: 'idaho' },
	IL: { stateName: 'Illinois', stateSlug: 'illinois' },
	IN: { stateName: 'Indiana', stateSlug: 'indiana' },
	KS: { stateName: 'Kansas', stateSlug: 'kansas' },
	KY: { stateName: 'Kentucky', stateSlug: 'kentucky' },
	LA: { stateName: 'Louisiana', stateSlug: 'louisiana' },
	MA: { stateName: 'Massachusetts', stateSlug: 'massachusetts' },
	MB: { stateName: 'Manitoba', stateSlug: 'manitoba' },
	MD: { stateName: 'Maryland', stateSlug: 'maryland' },
	ME: { stateName: 'Maine', stateSlug: 'maine' },
	MH: { stateName: 'Marshall Islands', stateSlug: 'marshall-islands' },
	MI: { stateName: 'Michigan', stateSlug: 'michigan' },
	MN: { stateName: 'Minnesota', stateSlug: 'minnesota' },
	MO: { stateName: 'Missouri', stateSlug: 'missouri' },
	MP: {
		stateName: 'Northern Mariana Islands',
		stateSlug: 'northern-mariana-islands',
	},
	MS: { stateName: 'Mississippi', stateSlug: 'mississippi' },
	MT: { stateName: 'Montana', stateSlug: 'montana' },
	NB: { stateName: 'New Brunswick', stateSlug: 'new-brunswick' },
	NC: { stateName: 'North Carolina', stateSlug: 'north-carolina' },
	ND: { stateName: 'North Dakota', stateSlug: 'north-dakota' },
	NE: { stateName: 'Nebraska', stateSlug: 'nebraska' },
	NH: { stateName: 'New Hampshire', stateSlug: 'new-hampshire' },
	NJ: { stateName: 'New Jersey', stateSlug: 'new-jersey' },
	NL: {
		stateName: 'Newfoundland and Labrador',
		stateSlug: 'newfoundland-and-labrador',
	},
	NM: { stateName: 'New Mexico', stateSlug: 'new-mexico' },
	NS: { stateName: 'Nova Scotia', stateSlug: 'nova-scotia' },
	NV: { stateName: 'Nevada', stateSlug: 'nevada' },
	NY: { stateName: 'New York', stateSlug: 'new-york' },
	OH: { stateName: 'Ohio', stateSlug: 'ohio' },
	OK: { stateName: 'Oklahoma', stateSlug: 'oklahoma' },
	ON: { stateName: 'Ontario', stateSlug: 'ontario' },
	OR: { stateName: 'Oregon', stateSlug: 'oregon' },
	PA: { stateName: 'Pennsylvania', stateSlug: 'pennsylvania' },
	PE: { stateName: 'Prince Edward Island', stateSlug: 'prince-edward-island' },
	PR: { stateName: 'Puerto Rico', stateSlug: 'puerto-rico' },
	PW: { stateName: 'Palau', stateSlug: 'palau' },
	QC: { stateName: 'Quebec', stateSlug: 'quebec' },
	RI: { stateName: 'Rhode Island', stateSlug: 'rhode-island' },
	SC: { stateName: 'South Carolina', stateSlug: 'south-carolina' },
	SD: { stateName: 'South Dakota', stateSlug: 'south-dakota' },
	SK: { stateName: 'Saskatchewan', stateSlug: 'saskatchewan' },
	TN: { stateName: 'Tennessee', stateSlug: 'tennessee' },
	TX: { stateName: 'Texas', stateSlug: 'texas' },
	UT: { stateName: 'Utah', stateSlug: 'utah' },
	VA: { stateName: 'Virginia', stateSlug: 'virginia' },
	VI: { stateName: 'Virgin Islands', stateSlug: 'virgin-islands' },
	VT: { stateName: 'Vermont', stateSlug: 'vermont' },
	WA: { stateName: 'Washington', stateSlug: 'washington' },
	WI: { stateName: 'Wisconsin', stateSlug: 'wisconsin' },
	WV: { stateName: 'West Virginia', stateSlug: 'west-virginia' },
	WY: { stateName: 'Wyoming', stateSlug: 'wyoming' },
};
