import React, { Fragment, FunctionComponent } from 'react';
import BreadcrumbCaret from '../shared/BreadcrumbCaret';
import BreadcrumbLink from '../shared/BreadcrumbLink';

export interface Props {
	clickHandler?: (analytics: Breadcrumbs.Analytics) => void;
	level: Breadcrumbs.Level;
}

const SingleLevel: FunctionComponent<Props> = ({ clickHandler, level }) => {
	return (
		<Fragment>
			<BreadcrumbCaret direction="left" extraTopMargin />
			<BreadcrumbLink
				url={level.url}
				label={level.label}
				onClick={() => clickHandler && clickHandler(level.analytics)}
			/>
		</Fragment>
	);
};

export default SingleLevel;
