export default {
	SOURCE_PAGE: {
		HOME: 'home',
		CATEGORY: 'category',
		CATEGORY_STATE: 'state',
		CATEGORY_CITY: 'category results',
		STOREFRONT_PAID: 'paid storefront',
		STOREFRONT_UNPAID: 'unpaid storefront',
	},
	ANALYTICS: {
		BREADCRUMB_INTERACTION: 'Breadcrumb Interaction',
		CLICK_THROUGH_TO_VENDOR_CATEGORY: 'Click-through to Vendor Category', // used for state and root link
	},
};
