import React, { FunctionComponent, MouseEvent, Fragment } from 'react';

import Styles from './styles.scss';

export interface Props {
	label: string;
	url: string;
	onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const BreadcrumbLink: FunctionComponent<Props> = ({ label, url, onClick }) => (
	<Fragment>
		<a className={Styles.link} href={url} onClick={onClick} itemProp="item">
			{label}
		</a>
	</Fragment>
);

export default BreadcrumbLink;
