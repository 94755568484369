import { useAppSelector } from '@redux/hooks';
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import BreadcrumbCaret from '../shared/BreadcrumbCaret';
import BreadcrumbInactive from '../shared/BreadcrumbInactive';
import BreadcrumbLink from '../shared/BreadcrumbLink';

export interface Props {
	clickHandler: (analytics: Breadcrumbs.Analytics) => void;
	levels: Breadcrumbs.Level[];
	isMobile?: boolean;
}

const useCaretDirection = (isMobile = false) => {
	const page = useAppSelector((state) => state.page);

	return useMemo(() => {
		if (page.pageType === 'storefront' && isMobile) {
			return 'slash';
		}

		return isMobile ? 'right' : 'slash';
	}, [isMobile, page]);
};

const MultiLevel: FunctionComponent<Props> = (props) => {
	const { clickHandler, levels, isMobile } = props;
	const inactiveBreadcrumb = levels[levels.length - 1];
	const activeLevels = levels.slice(0, levels.length - 1);
	const caretDirection = useCaretDirection(isMobile);

	return (
		<Fragment>
			<ol itemScope itemType="http://schema.org/BreadcrumbList">
				{activeLevels.map((level, index) => (
					<li
						key={level.url}
						itemProp="itemListElement"
						itemScope
						itemType="http://schema.org/ListItem"
					>
						<meta itemProp="position" content={`${index + 1}`} />
						<meta itemProp="name" content={level.label} />
						<BreadcrumbLink
							url={level.url}
							label={level.label}
							onClick={() => clickHandler(level.analytics)}
						/>
						<BreadcrumbCaret direction={caretDirection} />
					</li>
				))}
			</ol>
			<BreadcrumbInactive label={inactiveBreadcrumb.label} />
		</Fragment>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	isMobile: state.viewport.isMobile,
});

export default connect(mapStateToProps)(MultiLevel);
