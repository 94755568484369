import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import React, { Fragment, FunctionComponent, useMemo } from 'react';

import Styles from './styles.scss';

export interface Props {
	extraTopMargin?: boolean;
	direction: 'left' | 'right' | 'slash';
}

interface CaretProps {
	direction: 'left' | 'right';
	size: 'sm' | 'md';
}

const Caret = ({ direction, size }: CaretProps) => (
	<Icon
		name={direction === 'left' ? 'caret_left' : 'caret_right'}
		size={size}
	/>
);

const useCaretSize = () => {
	const page = useAppSelector((state) => state.page);

	return useMemo(() => {
		if (page.pageType === 'storefront') {
			return 'sm';
		}

		return 'md';
	}, [page]);
};

const BreadcrumbCaret: FunctionComponent<Props> = ({
	direction,
	extraTopMargin,
}) => {
	const caretSize = useCaretSize();

	return (
		<Fragment>
			<span
				className={
					extraTopMargin ? Styles.caretWithSpace : Styles.caret + Styles.center
				}
			>
				{direction === 'slash' ? (
					<div className={Styles.slash}>/</div>
				) : (
					<Caret direction={direction} size={caretSize} />
				)}
			</span>
		</Fragment>
	);
};

export default BreadcrumbCaret;
