import type { MutableRefObject } from 'react';

interface IsAbleToCreateObserverParams<T> {
	componentRef?: MutableRefObject<T>;
	targetVisible: boolean;
}

const createObserverConfig = <T extends HTMLElement>(
	ref?: MutableRefObject<T | null>,
) => ({
	root: ref?.current || null,
	rootMargin: '0px',
	threshold: 0.5,
});

const isAbleToCreateObserver = <T>({
	componentRef,
	targetVisible,
}: IsAbleToCreateObserverParams<T>): boolean =>
	componentRef?.current !== null && !targetVisible;

export { createObserverConfig, isAbleToCreateObserver };
