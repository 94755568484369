import { v4 as uuidv4 } from 'uuid';
import Vendor from '../../../../decorators/vendor';

export const vendorCardProps = (rawVendor) => {
	const vendor = new Vendor(rawVendor);

	return {
		capacity: vendor.guestCapacity,
		categoryCode: vendor.categoryCode,
		costTier: vendor.priceRange || vendor.startingPriceRange, // This could change based on Union's solution
		dek: vendor.dek,
		id: `${vendor.id}-${uuidv4()}`,
		imageAlt: '',
		imageUrl: vendor.storefrontCard.url || null,
		isBOW: vendor.awards.length > 0,
		isFeatured: false,
		location: {
			// https://github.com/xogroup/union/issues/1111
			city: vendor.serviceArea || vendor.address.city,
			state: vendor.serviceArea ? null : vendor.address.state,
		},
		marketcode: vendor.marketCode,
		name: vendor.name,
		reviewCount: vendor.reviewsCount,
		starCount: vendor.stars,
		url: vendor.uriData.relativeUri,
	};
};

export default vendorCardProps;
