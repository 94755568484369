export const canadianProvinces = [
	'AB',
	'BC',
	'MB',
	'NB',
	'NL',
	'NS',
	'NT',
	'NU',
	'ON',
	'PE',
	'QC',
	'SK',
	'YT',
];
