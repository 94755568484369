import React, { Fragment, FunctionComponent } from 'react';

import Styles from './styles.scss';

export interface Props {
	label: string;
}

const BreadcrumbInactive: FunctionComponent<Props> = ({ label }) => (
	<Fragment>
		<span className={Styles.inactiveBreadcrumb}>{label}</span>
	</Fragment>
);

export default BreadcrumbInactive;
