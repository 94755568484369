import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { createObserverConfig, isAbleToCreateObserver } from './utils';

type UseIsVisibleResult<T extends HTMLElement> = [
	MutableRefObject<T | null>,
	boolean,
	() => void,
];

interface UseIsVisibleOptions<T extends HTMLElement> {
	rootRef?: MutableRefObject<T | null>;
	callback?(isVisible: boolean): void;
	timeoutMs?: number;
}
export interface UseIsVisible {
	<TargetElement extends HTMLElement, RootElement extends HTMLElement>(
		options: UseIsVisibleOptions<RootElement>,
	): UseIsVisibleResult<TargetElement>;
}
/**
 * This hook is used to determine if an element is visible or not.
 *
 * @template TargetElement
 * @template RootElement
 * @param {UseIsVisibleOptions} [options]
 * @param {ComponentRef<TargetElement | null>} [options.rootRef]
 *  An optional root element that will be used as reference if the target element is visible.
 *  If not provided, the window will be used as reference.
 *  This is useful if the target element is in a nested component that has a set width/height and scrolls children.s
 * @param {(isVisible: boolean): void} [options.callback]
 *  An optional callback to be called when the visibility changes.
 *  This prevents re-rendering the target component.
 * @return {UseIsVisibleResult<TargetElement>}
 */

export const useIsVisible = <
	TargetElement extends HTMLElement,
	RootElement extends HTMLElement = HTMLElement,
>(
	options?: UseIsVisibleOptions<RootElement>,
): UseIsVisibleResult<TargetElement> => {
	const { rootRef, callback, timeoutMs = 1000 } = options || {};
	const [isVisible, setIsVisible] = useState(false);
	const isVisibleRef = useRef(false);
	const componentRef = useRef<TargetElement | null>(null);
	const observer = useRef<IntersectionObserver | null>(null);

	const createObserver = (): NodeJS.Timeout | null => {
		let timeout: NodeJS.Timeout | null = null;
		observer.current = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				timeout = setTimeout(() => {
					triggerIsVisible();
					if (observer.current && componentRef.current) {
						observer.current.unobserve(componentRef.current);
					}
				}, timeoutMs);
				return;
			}
			if (timeout) {
				clearTimeout(timeout);
			}
		}, createObserverConfig(rootRef));
		return timeout;
	};

	const triggerIsVisible = () => {
		if (typeof callback === 'function') {
			isVisibleRef.current = true;
			callback(true);
			return;
		}

		setIsVisible(true);
	};

	const reset = () => {
		if (isVisibleRef.current || isVisible) {
			if (observer.current && componentRef.current) {
				observer.current.observe(componentRef.current);
			}

			if (typeof callback === 'function') {
				isVisibleRef.current = false;
				return;
			}

			setIsVisible(false);
		}
	};

	useEffect(() => {
		const targetVisible = isVisibleRef.current || isVisible;
		if (isAbleToCreateObserver({ componentRef, targetVisible })) {
			const timeout = createObserver();

			if (observer.current && componentRef.current) {
				observer.current.observe(componentRef.current);
			}

			return () => {
				if (timeout) {
					clearTimeout(timeout);
				}
				if (observer.current) {
					observer.current.disconnect();
				}
			};
		}

		return () => {
			// Do nothing
		};
	}, [componentRef.current, rootRef]);

	return [componentRef, isVisible, reset];
};

export default useIsVisible;
