import { createSelector } from 'reselect';

const selectViewport = (state: Redux.State) => state.viewport;

export const selectIsMobileView = createSelector(selectViewport, (viewport) => {
	if (viewport?.isMobile) {
		return true;
	}
	if (viewport?.isClientSide && viewport.lessThan?.small) {
		return true;
	}
	return false;
});
