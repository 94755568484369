import React, { type FC, Fragment } from 'react';
import BreadcrumbsWrapper from './components';
import { breadcrumbsLevels } from './utils/breadcrumbsLevels';

/**
 * if city provided, stateCode also required
 * if stateCode provided, city also required
 * if vendor is provided, city and statecode required
 */
export type BreadcrumbsProps = {
	categoryCode: Category.CategoryCode;
	className?: string;
	isHeader?: boolean;
} & (
	| {
			city?: string | null;
			stateCode?: string | null;
			vendor?: Vendor.Decorated;
	  }
	| {
			city: string;
			stateCode: string;
			vendor?: Vendor.Decorated;
	  }
	| {
			city: string;
			stateCode: string;
			vendor: Vendor.Decorated;
	  }
);

const Breadcrumbs: FC<BreadcrumbsProps> = ({
	categoryCode,
	city,
	stateCode,
	vendor,
	isHeader = false,
	className,
}) => (
	<>
		<BreadcrumbsWrapper
			className={className}
			levels={breadcrumbsLevels(categoryCode, stateCode, city, vendor)}
			isHeader={isHeader}
		/>
	</>
);

export default Breadcrumbs;
