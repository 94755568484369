import { validateCitySlug } from '@utils';
import { stateAbbreviationNameSlugMap } from '../../../../../../helpers';

import { canadianProvinces } from '../../../../../../constants/canadian_provinces';

import Vendor from '../../../../../decorators/vendor';
import VendorAnalytics from '../../../../../decorators/vendorAnalytics';
import { codeToSlugNameMap } from '../../../../../utils/categoryNameMappings';
import Constants from '../../constants';

const vendorAnalyticsProps = (vendor) => {
	if (vendor) {
		const decoratedVendor = new Vendor(vendor);
		const vendorAnalytics = new VendorAnalytics(decoratedVendor);
		return vendorAnalytics.buildEventProps();
	}

	return {};
};

const addBreadcrumb = (breadcrumbs, props) => {
	const baseUrl = '/marketplace';
	const { url: urlFragment, label, categoryCode, vendor, sourcePage } = props;

	breadcrumbs.unshift({
		url: `${baseUrl}${urlFragment}`,
		label,
		analytics: {
			vendorCategoryCode: categoryCode,
			sourceContent: 'breadcrumb',
			...vendorAnalyticsProps(vendor),
			sourcePage,
			selection: label,
		},
	});
};

export const categoryInfo = (categoryCode) => {
	const category = codeToSlugNameMap[categoryCode];

	// Because of the SEO value, we want to use
	// Wedding Venues instead of Reception Venues
	if (category && categoryCode === 'REC') {
		category.name = 'Wedding Venues';
	}

	return category || {};
};

export const breadcrumbsLevels = (categoryCode, stateCode, city, vendor) => {
	const { SOURCE_PAGE } = Constants;
	const category = categoryInfo(categoryCode);
	const { stateName, stateSlug } =
		stateAbbreviationNameSlugMap[stateCode] || {};
	const breadcrumbs = [];
	let sourcePage;

	// source page represents page from which click originated from
	// storefront level is inctive - no link, no need for tracking info
	if (category && stateCode && city && vendor) {
		sourcePage =
			vendor.purchaseStatus === 'PAID'
				? SOURCE_PAGE.STOREFRONT_PAID
				: SOURCE_PAGE.STOREFRONT_UNPAID;
		addBreadcrumb(breadcrumbs, {
			label: vendor.name,
		});
	}

	if (category && stateCode && city) {
		const slugifiedCity = validateCitySlug(city);
		addBreadcrumb(breadcrumbs, {
			label: `${city} ${category.name}`,
			url: `/${category.href}-${slugifiedCity}-${stateCode}?sort=featured`.toLowerCase(),
			sourcePage: sourcePage || SOURCE_PAGE.CATEGORY_CITY,
			categoryCode,
			vendor,
		});
	}

	if (
		category &&
		stateCode &&
		!canadianProvinces.includes(stateCode) &&
		stateCode !== 'DC'
	) {
		// on state pages we are displaying only state name
		// on search apages state name + category name
		addBreadcrumb(breadcrumbs, {
			label: city ? `${stateName} ${category.name}` : stateName,
			url: `/${category.href}/${stateSlug}?redirectToCity=false`,
			sourcePage: sourcePage || SOURCE_PAGE.CATEGORY_STATE,
			categoryCode,
			vendor,
		});
	}

	if (category) {
		addBreadcrumb(breadcrumbs, {
			label: category.name,
			url: `/${category.href}?redirectToCity=false`,
			sourcePage: sourcePage || SOURCE_PAGE.CATEGORY,
			categoryCode,
			vendor,
		});
	}

	addBreadcrumb(breadcrumbs, {
		label: 'Wedding Vendors',
		url: '',
		sourcePage,
		categoryCode,
		vendor,
	});

	return breadcrumbs;
};
